@import '../theme-bootstrap';

$advent-calendar-tile-color: #002640;
$advent-calendar-box-shadow: rgba(#001a2a, 0.48);
$advent-calendar-close-color: #2b2a2a;

#adv_cal_cap_container {
  @include breakpoint($medium-portrait-only) {
    height: auto;
  }
  @include breakpoint($medium-landscape-only) {
    height: 900px;
  }
  @include breakpoint($small-only-landscape) {
    height: auto;
  }
  @include breakpoint($large-up) {
    height: 800px;
    position: inherit;
  }
  .adv-cal-cap-wrapper {
    .adv-cal-cap-title,
    .adv-cal-cap-subtitle {
      margin: 1em;
    }
    box-sizing: border-box;
    margin-bottom: 50px;
    // Default
    width: 100%;
    @include breakpoint($medium-portrait-only) {
      width: 1024px !important;
    }
    @include breakpoint($medium-up) {
      width: 810px !important;
    }
    @include breakpoint($large-up) {
      width: 1020px !important;
    }
    @include breakpoint($large-only) {
      width: 1200px !important;
    }
    .cards {
      align-items: flex-start;
      .card {
        -webkit-flex: unset;
        flex: unset;
        height: 100%;
        max-width: 50%;
        height: auto;
        aspect-ratio: 373 / 620;
        margin-bottom: 0;
        &.p1,
        &.p2 {
          aspect-ratio: 373 / 510;
          @include breakpoint($medium-up) {
            aspect-ratio: unset;
          }
        }
        &.p11,
        &.p12 {
          aspect-ratio: 373 / 485;
          @include breakpoint($medium-up) {
            aspect-ratio: unset;
          }
        }
        @include breakpoint($medium-portrait-only) {
          width: 127px !important;
          &.p7 {
            top: 174px;
          }
          &.p9,
          &.p11 {
            top: -85px;
          }
          &.p12 {
            top: -47px;
          }
        }
        @include breakpoint($medium-up) {
          aspect-ratio: unset;
          margin-bottom: 0;
          width: 134px !important;
          &.p7 {
            top: -40px;
          }
          &.p9,
          &.p11 {
            top: -91px;
          }
          &.p12 {
            top: -51px;
          }
        }
        @include breakpoint($large-up) {
          width: 170px !important;
          &.p7 {
            top: -51px;
          }
          &.p9,
          &.p11 {
            top: -114px;
          }
          &.p12 {
            top: -63px;
          }
        }
        @include breakpoint($large-only) {
          width: 200px !important;
          &.p7 {
            top: -60px;
          }
          &.p9,
          &.p11 {
            top: -135px;
          }
          &.p12 {
            top: -75px;
          }
        }
        // Responsive video
        .card-content {
          video {
            width: 100%;
            height: auto;
            position: relative;
          }
        }
        // Status overrides
        &.active,
        &.future,
        &.expired,
        &.tout {
          &:hover {
            box-shadow: none;
          }
          box-shadow: none;
          border: none;
        }
        // Overlay
        &.future::before,
        &.expired::before {
          position: absolute;
          content: '';
          background: #{$color-black-50};
          z-index: 1;
          height: 100%;
          width: 100%;
        }
        .card-content .offer-img-bkg {
          color: $white;
          padding: 5px;
          max-width: 100%;
          min-height: 100%;
          height: 100% !important;
          .offer-section-copy {
            font-size: 12px;
            p {
              margin-bottom: 0;
            }
          }
          .button {
            @include button--secondary;
            min-width: 100%;
            max-width: 100%;
            height: 40px;
            line-height: 2.6;
            padding: 0;
          }
        }
        .offer-section {
          height: 100%;
        }
        .offer-section-details {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .card-product-img {
          flex: 1;
          height: 150px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-color: $white;
          margin-bottom: 5px;

          svg {
            aspect-ratio: 1;
            width: 20px;
            position: absolute;
            #{$rdirection}: 12px;
            top: 10px;
          }
        }
        .card-product-cta {
          min-height: 40px;
          margin-top: 7px;
          margin-bottom: 2px;
        }
        &.p2,
        &.p4,
        &.p9,
        &.p11 {
          .card-product-img {
            height: 210px;
          }
        }
        &.p3,
        &.p5,
        &.p8,
        &.p10 {
          .card-product-img {
            height: 140px;
          }
        }
      }
    }
  }
  .adv-cal-cap-popup {
    position: fixed;
    background: none;
    @include breakpoint($medium-up) {
      position: absolute;
    }
    .popup-content-wrapper {
      background-color: $advent-calendar-tile-color;
      border-radius: 0;
      height: auto;
      width: 285px;
      box-shadow: 3px 4px 2.88px 0.12px $advent-calendar-box-shadow;
      transform: translate(-50%, -50%);
      top: 50% !important;
      @include breakpoint($small-down) {
        #{$ldirection}: 50% !important;
      }
      @include breakpoint($medium-up) {
        top: 0 !important;
        transform: translate(-50%, 170px);
      }
      .popup-close {
        top: 32px;
        #{$rdirection}: 28px;
        background: none;
        &::before,
        &::after {
          background-color: $advent-calendar-close-color;
          width: 1px;
          height: 16px;
        }
        @include breakpoint($medium-portrait-only) {
          height: 25px;
          #{$rdirection}: 30px;
          top: 30px;
        }
        @include breakpoint($large-up) {
          top: 32px;
          #{$rdirection}: 32px;
        }
      }
      .offer-details {
        transform: scale(0);
        animation: zoom 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        color: $color-white;
        &__panel {
          width: 100%;
          height: auto;
          padding: 12px;
          @include breakpoint($large-up) {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }
        &__img {
          width: 100%;
          margin: 0 auto;
          background: $white;
          text-align: $ldirection;
          @include breakpoint($large-up) {
            margin-#{$ldirection}: 0;
          }
          & img {
            display: block;
            margin: 0 auto;
            @include breakpoint($large-up) {
              margin: 0;
            }
          }
        }
        &__content {
          text-align: center;
          margin: 0;
          padding: 30px 5px 0;
          float: none;
          clear: both;
          @include breakpoint($large-up) {
            position: relative;
            top: 0;
            text-align: $ldirection;
            clear: none;
            margin: auto 0 0;
          }
          &__title {
            margin-bottom: 12px;
            text-transform: uppercase;
            font-size: 30px;
            line-height: 1;
            letter-spacing: 1px;
            text-align: center;
            font-family: $font--lamer-headline;
            font-style: italic;
            @include breakpoint($large-up) {
              line-height: 0.95;
            }
          }
          &__description {
            font-family: $font--neue-haas-unica-pro;
            text-align: center;
            font-size: 16px;
            padding: 0 10px;

            a {
              display: inline-block;
              color: $color-white;
              margin: 0 4px;
            }
          }
        }
        &__controls {
          width: 100%;
          margin-top: 10px;
          @include breakpoint($large-up) {
            width: auto;
            position: relative;
            top: 0;
            height: 40px;
            margin: 0 0 auto;
          }
          .btn-wrapper {
            text-align: center;
          }
          .button {
            @include button--secondary;
            padding: 0;
            line-height: 2.6;
            height: 40px;
          }
        }
      }
    }
    @keyframes zoom {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}


.adv_cal_cap .adv-cal-cap-container .adv-cal-cap-wrapper .cards .card.tile{
  &:not(.enable-hover) .card-content .offer-img-bkg {
    display: none;
  }
  &.enable-hover {
    background: $advent-calendar-tile-color;
    .card-content {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;

      > * {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;

        &:last-child {
          transform: rotateY(180deg) translateZ(2px);
        }
      }

      .offer-img-bkg {
        transition: opacity .2s .2s;
      }
    }

    &:hover,
    &.opened {
      .card-content {
        transform: rotateY(180deg) translateZ(1px);
      }
      .offer-img-bkg {
        opacity: 1;
      }
    }
  }
}
